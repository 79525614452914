import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import './../../assets/css/imageSlide.css';
import { Autoplay, Pagination } from 'swiper/modules';
import { useContext } from 'react';
import WebsiteSettingsContext from '../../contexts/WebsiteSetting';
import { SlideImageType } from '../../types/Settings';

const SlideImages = () => {
    const { websiteSetting } = useContext(WebsiteSettingsContext);

    return (
        (websiteSetting && websiteSetting.slide_image && websiteSetting.slide_image.length > 0) ? <Swiper
            slidesPerView={1}
            spaceBetween={0}
            pagination={{
                clickable: true,
            }}
            loop={true}
            autoplay={{
                delay: 3500,
                disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination]}
            className="header-swiper"
        >
            {
                websiteSetting.slide_image.map((image: SlideImageType, index: number) => (
                    <SwiperSlide key={`home-swiper-${index}`}>
                        <img src={`${process.env.REACT_APP_API_URL}/cdn/${image.path}`} alt={`${image.path}`} />
                    </SwiperSlide>
                ))
            }
            
        </Swiper> : null
    )
}

export default SlideImages;