import React, { forwardRef, HTMLAttributeAnchorTarget, ReactNode } from "react";
import { Link } from "react-router-dom";

interface IButtonProps {
  children?: ReactNode;
  tag?: "button" | "a" | "input" | "link";
  type?: "button" | "submit" | "reset";
  to?: string;
  href?: string;
  isActive?: boolean;
  color?: string;
  isOutline?: boolean;
  isLight?: boolean;
  isLink?: boolean;
  className?: string;
  rounded?: "none" | "sm" | "md" | "lg" | "full";
  size?: "sm" | "md" | "lg";
  isDisable?: boolean;
  target?: HTMLAttributeAnchorTarget;
  onClick?: () => void;
}

const Button = forwardRef<HTMLAnchorElement, IButtonProps>(
  (
    {
      children,
      tag = "button",
      type = "button",
      to,
      href,
      isActive,
      color = "blue", // Default Tailwind color
      isOutline,
      isLight,
      isLink,
      className,
      rounded = "md",
      size = "md",
      isDisable,
      target,
      ...props
    },
    ref
  ) => {
    const BTN_CLASS = `
      transition duration-300 ease-in-out font-medium inline-flex items-center justify-center
      ${!isOutline && !isLight && !isLink ? `px-4 py-2 text-white bg-${color}-500 hover:bg-${color}-600` : ""}
      ${isOutline ? `px-4 py-2 border border-${color}-500 text-${color}-500 hover:bg-${color}-100` : ""}
      ${isLight ? `px-4 py-2 bg-${color}-100 text-${color}-600` : ""}
      ${isLink ? "underline text-blue-500 hover:text-blue-700" : ""}
      ${rounded === "none" ? "rounded-none" : rounded === "sm" ? "rounded-sm" : rounded === "md" ? "rounded-md" : rounded === "lg" ? "rounded-lg" : "rounded-full"}
      ${size === "sm" ? "text-sm" : size === "lg" ? "text-lg" : ""}
      ${isDisable ? "opacity-50 cursor-not-allowed" : ""}
      ${isActive ? "ring-2 ring-blue-300" : ""}
      ${className || ""}
    `;

    if (tag === "a") {
      if (to) {
        return (
          <Link ref={ref} className={BTN_CLASS} to={to} target={target} {...props}>
            {children}
          </Link>
        );
      }
      return (
        <a ref={ref} className={BTN_CLASS} href={href} target={target} {...props}>
          {children}
        </a>
      );
    }

    return (
      <button type={type} className={BTN_CLASS} disabled={isDisable} {...props}>
        {children}
      </button>
    );
  }
);

Button.displayName = "Button";

export default Button;