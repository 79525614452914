import { useContext, useEffect, useState } from "react";
import { GameType, ProviderType } from "../../../types/Games";
import Button from "../../_tailwild/Button";
import GamesContext from "../../../contexts/GamesContext";
import AuthContext from "../../../contexts/AuthContext";
import LoginContext from "../../../contexts/LoginContext";
import { ApiService } from "../../../services/api.service";

interface PropsType {
    category_name: string;
}

const HomeGame = ({ category_name }: PropsType) => {
    const { games } = useContext(GamesContext);
    const { accessToken } = useContext(AuthContext);
    const { setActiveTab, setLoginModalIsOpen } = useContext(LoginContext);
    const [gettingStartGameUrl, setGettingStartGameUrl] = useState<boolean>(false);

    const startGame = async (game_id: string, provider: string, tab: string) => {
        if(!accessToken)
        {
            setActiveTab('login');
            return setLoginModalIsOpen(true);
        }

        if(gettingStartGameUrl)
        {
            return;
        }

        setGettingStartGameUrl(true);
        try {
            const service = new ApiService();
            const requestGetRedirect = await service.getRedirectGameUrl(accessToken, game_id, provider, tab);

            if(requestGetRedirect.data.status_code === 200)
            {

            }
        } catch (err) {
            console.error(err);
        } finally {
            setGettingStartGameUrl(false);
        }
    }

    return (
        <div className="grid grid-cols-12 gap-1 mt-2">
            {games.filter((game: GameType) => game.gameCategory === category_name).slice(0, 6).map((game: GameType, index: number) => (
                <div
                    className="col-span-4 lg:col-span-2 relative group mb-10"
                    key={`${index}-game-${category_name}-${game.provider_provider}-${game.gameName.replace(/ /g, "_").toLowerCase()}`}
                >
                    <img
                        className="rounded-lg w-full h-full object-cover"
                        src={game.image_vertical} 
                    />
                    <div>
                        <p className="text-md leading-none mt-2">
                            {
                                game.gameName
                            }
                        </p>
                        <p className="text-sm text-gray-400 leading-none">
                            {
                                game.provider_name ? game.provider_name : game.provider
                            }
                        </p>
                    </div>
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg">
                        <Button
                            onClick={() => startGame(game.game_id, game.provider, game.gameCategory)}
                        >
                            PLAY
                        </Button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default HomeGame;
