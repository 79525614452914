import { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { ApiService } from '../services/api.service';
import { CategoryType, GameType } from '../types/Games';

export interface GamesContextProp {
    games: GameType[],
    categories: CategoryType[],
    isLoadingGames: boolean
}

const GamesContext = createContext<GamesContextProp>({} as GamesContextProp);
interface IAuthContextProviderProps {
    children: ReactNode;
}

export const GamesContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
    const [isLoadingGames, setIsLoadingGames] = useState<boolean>(false);
    const [games, setGames] = useState<GameType[]>([]);
    const [categories, setCategories] = useState<CategoryType[]>([]);

    useEffect(() => {
        (async () => {
            await getGamesAndCategories();
        })();
    }, []);

    const getGamesAndCategories = async () => {
        setIsLoadingGames(true);
        try {
            const service = new ApiService();
            const requestGetData = await service.getGamesAndCategories();

            if(requestGetData.data.status_code === 200)
            {
                setGames(requestGetData.data.games);
                setCategories(requestGetData.data.categories);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoadingGames(false);
        }
    }

    const value = useMemo(
        () => ({
            games,
            categories,
            isLoadingGames
        }),
        [games, categories, isLoadingGames]
    );
    return <GamesContext.Provider value={value}>{children}</GamesContext.Provider>;
};

export default GamesContext;