import { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';

export interface LoginContextProps {
    loginModalIsOpen: boolean,
    setLoginModalIsOpen: (state: boolean) => void,
    activeTab: string,
    setActiveTab: (tab: string) => void
}

const LoginContext = createContext<LoginContextProps>({} as LoginContextProps);
interface IAuthContextProviderProps {
    children: ReactNode;
}

const tabs = ['login', 'register'];
export const LoginContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
    const [loginModalIsOpen, setLoginModalIsOpen] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string>(tabs[0]);

    useEffect(() => {
        setLoginModalIsOpen(loginModalIsOpen);
    }, [loginModalIsOpen]);

    const value = useMemo(
        () => ({
            setLoginModalIsOpen,
            loginModalIsOpen,
            activeTab,
            setActiveTab
        }),
        [loginModalIsOpen, activeTab]
    );
    return <LoginContext.Provider value={value}>{children}</LoginContext.Provider>;
};

export default LoginContext;