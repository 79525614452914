import { useContext } from "react";
import MenuIcon from "./../../assets/images/icon_menu.png";
import LoginContext from "../../contexts/LoginContext";

const Header = () => {
    const { setLoginModalIsOpen, setActiveTab } = useContext(LoginContext);
    
    const openLoginModal = (tab: string) => {
        setActiveTab(tab);
        setLoginModalIsOpen(true);
    }

    return (
        <>
            <div className="w-full bg-white shadow-md fixed top-0 left-0 z-50">
                <div className="container mx-auto">
                    <div className="flex justify-between p-3 gap-3">
                        <div className="flex items-center gap-2">
                            <div className="cursor-pointer">
                                <img src={MenuIcon}  style={{ maxWidth: '25px' }} />
                            </div>
                            <div className="text-sm">
                                LOGO
                            </div>
                        </div>
                        <div className="flex items-center gap-2">
                            <div>
                                <button
                                    className="bg-white px-5 py-1 rounded hover:bg-blue-500 hover:text-white focus:outline-none transition duration-300 ease-in-out border-2 border-blue-300 hover:border-blue-500 text-sm"
                                    onClick={() => openLoginModal('login')}
                                >
                                    Login
                                </button>
                            </div>
                            <div>
                                <button
                                    className="bg-blue-500 text-white py-1 px-5 rounded hover:bg-blue-600 focus:outline-none transition duration-300 ease-in-out border-2 border-blue-500 hover:border-blue-600 text-sm"
                                    onClick={() => openLoginModal('register')}
                                >
                                    Register
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-20"></div>
        </>
    )
}

export default Header;