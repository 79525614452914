import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import Header from "./components/_layouts/Header";
import LoginRegisterModal from "./components/_layouts/Modals/LoginRegisterModal";
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const cookies = new Cookies(null, { path: '/' });

    return (
       <>
            <ReactNotifications />
            <Header />
            <LoginRegisterModal />
            <Outlet/>
       </>
    )
}

export default App;