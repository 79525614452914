import { useFormik } from 'formik';
import showNotification from '../../_utils/showNotification';
import { useContext, useEffect, useRef, useState } from 'react';
import AuthContext from '../../../contexts/AuthContext';
import { ApiService } from '../../../services/api.service';
import Button from '../../_tailwild/Button';
import LoginContext from '../../../contexts/LoginContext';
import WebsiteSettingsContext from '../../../contexts/WebsiteSetting';

const LoginTab = () => {
    const { activeTab, loginModalIsOpen, setLoginModalIsOpen } = useContext(LoginContext);
    const { accessToken, setAccessToken } = useContext(AuthContext);
    const { websiteSetting } = useContext(WebsiteSettingsContext);
    const [loading, setLoading] = useState<boolean>(false);
    const usernameRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if(loginModalIsOpen && activeTab === 'login')
        {
            if(usernameRef && usernameRef.current)
            {
                usernameRef.current.focus()
            }
        }
    }, [loginModalIsOpen, activeTab]);

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validate: (values) => {
            const errors: {
                username?: string;
                password?: string;
            } = {};
			if (!values.username) {
				errors.username = 'Required';
			}
			if (!values.password) {
				errors.password = 'Required';
			}

            return errors;
        },
        onSubmit: async (values) => {
            setLoading(true);

            try {
                const service = new ApiService();
                const requestLogin = await service.login(values.username, values.password, process.env.REACT_APP_WEBSITE_ID || 0);
                const responseCode = requestLogin.data.status_code;

                if(responseCode === 200 && requestLogin.data.member_data.access_token)
                {
                    setAccessToken?.(requestLogin.data.member_data.access_token);
                    setLoginModalIsOpen(false);
                }

                showNotification(
                    "Notification",
                    responseCode === 200 ? "Login successfully" : `Login failed (${responseCode} - ${requestLogin.data.message})`,
                    responseCode === 200 ? "success" : "danger",
                    responseCode === 200 ? 3000 : 5000
                );
            } catch (error) {
                console.error('Login failed', error);
            } finally {
                setLoading(false);
            }
        },
    });

    return (
        <form className="space-y-4" onSubmit={formik.handleSubmit}>
            <div>
                <label htmlFor='username' className="block text-sm font-medium text-gray-700">
                    {
                        websiteSetting ? (websiteSetting.login_username_or_phone === 'username' ? "Username" : (
                            websiteSetting.login_username_or_phone === 'phone' ? "Phone number" : "Unknown"
                        )) : ""
                    }
                </label>
                <input 
                    id="username"
                    name="username"
                    ref={usernameRef}
                    className={`block w-full p-2 border ${formik.errors.username ? "border-red-500" : "border-gray-300"} rounded-md focus:ring focus:ring-indigo-200`}
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {/* {
                    formik.errors.username && formik.touched.username && (
                        <div className="text-red-500 text-sm">{formik.errors.username}</div>
                    )
                } */}
            </div>
            <div>
                <label htmlFor='password' className="block text-sm font-medium text-gray-700">Password</label>
                <input 
                    id="password" 
                    name="password" 
                    type="password"
                    className={`block w-full p-2 border ${formik.errors.password ? "border-red-500" : "border-gray-300"} rounded-md focus:ring focus:ring-indigo-200`}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {/* {
                    formik.errors.password && formik.touched.password && (
                        <div className="text-red-500 text-sm">{formik.errors.password}</div>
                    )
                } */}
            </div>
            <Button isDisable={loading} type="submit" className="w-full bg-indigo-600 text-white p-2 rounded-md hover:bg-indigo-700 disabled:bg-gray-400">
                Login
            </Button>
        </form>
    )
}

export default LoginTab;