import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// CSS
import './assets/css/index.css';
import 'react-notifications-component/dist/theme.css';

// COMPONENTS
import App from './App';
import Home from './components/Home/Home';

// CONT
import { AuthContextProvider } from './contexts/AuthContext';
import { LoginContextProvider } from './contexts/LoginContext';
import { GamesContextProvider } from './contexts/GamesContext';
import { WebsiteSettingsContextProvider } from './contexts/WebsiteSetting';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        element: <App />,
        children: [
            {
                path: "/", element: <Home />
            },
        ]
    }
]);

root.render(
    <WebsiteSettingsContextProvider>
        <AuthContextProvider>
            <LoginContextProvider>
                <GamesContextProvider>
                    <React.StrictMode>
                        <RouterProvider router={router} />
                    </React.StrictMode>
                </GamesContextProvider>
            </LoginContextProvider>
        </AuthContextProvider>
    </WebsiteSettingsContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
