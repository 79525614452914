import { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { ApiService } from '../services/api.service';
import { CategoryType, GameType } from '../types/Games';
import { WebsiteSettingsType } from '../types/Settings';

export interface WebsiteSettingsContextProp {
    websiteSetting: WebsiteSettingsType | null,
}

const WebsiteSettingsContext = createContext<WebsiteSettingsContextProp>({} as WebsiteSettingsContextProp);
interface IAuthContextProviderProps {
    children: ReactNode;
}

export const WebsiteSettingsContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
    const [isLoadingSettings, setIsLoadingSettings] = useState<boolean>(false);
    const [websiteSetting, setWebsiteSetting] = useState<WebsiteSettingsType | null>(null);

    useEffect(() => {
        (async () => {
            await getWebsiteSettings();
        })();
    }, []);

    const getWebsiteSettings = async () => {
        setIsLoadingSettings(true);
        try {
            const service = new ApiService();
            const requestGetData = await service.getWebsiteSettings();

            if(requestGetData.data.status_code === 200)
            {
                setWebsiteSetting(requestGetData.data.settings);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoadingSettings(false);
        }
    }

    const value = useMemo(
        () => ({
            websiteSetting,
            isLoadingSettings
        }),
        [websiteSetting, isLoadingSettings]
    );
    return <WebsiteSettingsContext.Provider value={value}>{children}</WebsiteSettingsContext.Provider>;
};

export default WebsiteSettingsContext;