import { useContext } from "react";
import LoginContext from "../../../contexts/LoginContext";
import Modal from "../../_utils/Modal";
import Tabs from "../../_utils/Tabs";
import LoginTab from "./LoginTab";
import RegisterTab from "./RegisterTab";

const tabs = ['login', 'register'];

const LoginRegisterModal = () => {
    const { loginModalIsOpen, setLoginModalIsOpen, activeTab, setActiveTab } = useContext(LoginContext);

    return (
        <Modal
            isOpen={loginModalIsOpen}
            onClose={() => setLoginModalIsOpen(false)}
            title={
                <Tabs
                    tabs={tabs}
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                />
            }
        >
            <div className="my-3"></div>
            {
                activeTab === "login" && <LoginTab />
            }
            {
                activeTab === "register" && <RegisterTab />

            }
        </Modal>
    )
}

export default LoginRegisterModal;