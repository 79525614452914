import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../contexts/AuthContext";
import { ApiService } from "../../../services/api.service";
import { CategoryType, ProviderType } from "../../../types/Games";
import HomeGame from "./HomeGame";
import GamesContext from "../../../contexts/GamesContext";
import WebsiteSettingsContext from "../../../contexts/WebsiteSetting";

const HomeOverAllGames = () => {
    const { accessToken } = useContext(AuthContext);
    const { websiteSetting } = useContext(WebsiteSettingsContext);
    const [loadingCategory, setLoadingCategory] = useState<boolean>(false);
    const { categories } = useContext(GamesContext);
    
    return (
        <div className="grid grid-cols-12 gap-3">
            {
                (websiteSetting && websiteSetting.main_page_category_show) && categories.map((category: CategoryType, index: number) => {
                    return (
                        (websiteSetting.main_page_category_show[category.name.toLowerCase()]) ? <div className="col-span-12" key={`home-category-${index}`}>
                            <p className="text-lg">
                                {category.name}
                            </p>
                            <hr />
                            <HomeGame category_name={category.name} />
                        </div> : null
                    )
                })
            }
        </div>
    )
}

export default HomeOverAllGames;